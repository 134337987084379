body {
  background-color: #ffffff;
}

/* Ocultar la barra de desplazamiento */
::-webkit-scrollbar {
  display: none; /* Ocultar scrollbar en Chrome, Safari y Opera */
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Montserrat';
  scroll-behavior: smooth;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.carousel-inner, .carousel-item, .carousel {
  width: 100%;
}

.carousel-container, .carousel {
  width: 100vw; /* Para asegurar que ocupe toda la ventana */
  max-width: 100vw; /* Evita que exceda el tamaño de la ventana */
  overflow: hidden; /* Oculta cualquier desbordamiento innecesario */
}

.carousel-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1000;
}

.carousel {
  display: flex;
  transition: transform 0.5s ease;
  z-index: 1000;
}

.carousel-slide {
  width: 100vw; /* Hacemos que el ancho de cada slide sea el 100% de la ventana */
  position: relative;
  flex-shrink: 0; /* Asegura que los slides no se encojan */
}

.carousel-slide img {
  width: 100%; /* Full width of the slide */
  height: 100vh; /* Full height of the viewport */
  object-fit: cover; /* Mantiene la relación de aspecto */
}

.overlay-content {
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translateY(-50%);
  color: white;
  text-align: left;
  z-index: 5; /* Asegura que el contenido esté encima de la imagen */
}

.overlay-content h2 {
  font-size: 3vw;
  margin-left: 20vw;
  font-weight: 500;
  margin-bottom: .5vw;
  margin-top: -2px;
  font-family: 'Caveat';
 
}

.overlay-content p {
  font-size: 1.25vw;
  max-width: 37vw;
  margin-left: 20vw;
  font-weight: 100;
  font-family: 'Raleway';
  margin-bottom: -.1vw;
}

.logo2 {
  width: 11vw;
  margin-bottom: 10px;
  margin-left: 20vw;
  margin-top: 20vw;
}

.fr{
  width: 1vw;
}

/* Flechas de navegación */
.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 0, 0, 0.9); /* Color de fondo con transparencia */
  color: white;
  padding: 15px;
  cursor: pointer;
  z-index: 10; /* Asegura que las flechas estén siempre encima */
  opacity: 0.8;
}

/* Círculo blanco alrededor de la flecha */
.arrow-circle {
  display: inline-block;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid white; /* Borde blanco */
  background-color: transparent; /* Sin relleno */
}

/* Estilos para el enlace "Más" */
.more-link {
  color: white;
  font-size: 1vw;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  margin-top: 10px;
  margin-left: 20vw;
}

.more-link:hover,
.more-link:focus {
  color: white; /* Mantener el color blanco */
  outline: none; /* Eliminar el contorno predeterminado */
}

.carousel-inner .carousel-item:nth-last-child(-n+3) .overlay-content {
  margin-left: 7vw;
}

.carousel-inner .carousel-item:nth-child(5) .overlay-content{
  margin-left: 1vw; /* Ajusta el margen según necesites */
  margin-top: -5vw;
}

.footer-gray2-line {
  background-color: #ffffff;
  height: 2px;
  width: 15vw;
  margin-left: 20vw;
}

/* Círculo pequeño alrededor de la flecha en el enlace "Más" */
.small-arrow-circle {
  display: inline-flex;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-left: 5px;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  background-color: transparent;
}

.small-arrow-circle .carousel-control-next-icon {
  width: 18px; /* Ajusta el tamaño del ícono */
  height: 18px;
  background-size: 10px 10px; /* Escala el fondo del ícono */
}

.carousel-arrow:hover {
  opacity: 1;
}

/* Alineación de las flechas a los extremos de la pantalla */
.left-arrow {
  left: 0; /* Posicionamos la flecha izquierda en el extremo izquierdo */
}

.right-arrow {
  right: 0; /* Posicionamos la flecha derecha en el extremo derecho */
}

/* Indicadores del carrusel (puntitos) */
.carousel-indicators {
  display: flex;
  justify-content: center; /* Centra los puntitos horizontalmente */
  position: absolute;
  bottom: 20px;
  width: 100%;
  z-index: 10;
  left: -15vw;
}

.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #4D4D4D;
  border-radius: 50%;
  margin: 5px;
  cursor: pointer;
}

.dot.active {
  background-color: #EC2227;
}


/*VATRISA INFO*/


.info-section {
  background-color: #ffffff;
  padding: 0 20vw 0 13vw ;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;

}

.rectangle {
  display: flex;
  width: 100%;
 
  background-color: #54565A; /* Fondo gris oscuro */
  margin: 4vw; /* Margen externo del rectángulo */
}

.k{
  border-width: 2px;
  border-style: solid;
  border-color: red;
}


.image-side {
  flex: .6;
}

.image-side img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover; /* Asegura que la imagen se ajuste correctamente */
  margin: 0;
  border: none; /* Sin bordes redondeados */
  
}

.text-side {
  flex: 1.4;
  padding: 2vw; /* Ajusta el padding si quieres más espacio */
  color: white;
  position: relative; /* Para que la flecha se posicione dentro de este contenedor */
}

.text-side h2 {
  font-size: 2vw;
  font-weight: 900;
  margin-bottom: 5px;
  margin-top: 1px;
  font-family: 'Helvetica';
}

.text-side h3 {
  font-size: 1.4vw;
  font-weight: 200;
  margin-bottom: 2vw;

}

.text-side p {
  font-size: 1.1vw;
  line-height: 1.6;
  margin-bottom: 15px;
  font-weight: 100;
  font-family: 'Raleway';
}

.more-info {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0; /* Sin márgenes */
}

.more-info img {
  width: 2.5vw;
  height: 3vw;
  display: inline-block;
}

.footer-gray3-line {
  background-color: #ffffff;
  height: 2px;
  width: 23vw;
  margin-bottom: 1vw;
}

/*VATRISA PRODUCTOS*/



/* Contenedor principal */
.custom-product-carousel {
  text-align: center;
  padding-bottom: 10vw;
  padding-top: 1vw;
  background-color: #fff;
}

.custom-product-carousel h2 {
  font-family: 'Raleway';
  font-weight: 500;
  font-size: 2.4vw;
  margin-bottom: 2vw;
  color: #d32f2f;
}

/* Estilo del carrusel */
.custom-carousel {
  position: relative;
  width: 130%;
  max-width: 100vw;
  margin: 0 auto;
}

/* Imágenes grandes al fondo */
.custom-carousel-back {
  display: flex;
  overflow: hidden;
}

.custom-large-container {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  justify-content: center;
}

.custom-large-image {
  width: 50%; /* Asegura que las imágenes ocupen la mitad cada una */
  object-fit: cover;
  margin: 0; /* Sin margen entre imágenes */
  border-radius: 0; /* Elimina bordes redondeados */
}

.custom-carousel-front {
  position: absolute;
  bottom: -55px; /* Ajustamos la posición un poco más hacia abajo */
  display: flex;
  justify-content: center; /* Centra los logos dentro del contenedor */
  align-items: center;
  background-color: white; /* Fondo blanco para cada logo */
  padding: 1vw; /* Espaciado interno */
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2); /* Sombra para resaltar */
  width: 10%; /* Ancho de cada contenedor frontal */
  height: 20%;
}

.custom-carousel-front.front-left {
  left: 18%; /* Posición a la izquierda */
}

/* Posicionamiento para el contenedor derecho */
.custom-carousel-front.front-right {
  right: 18%; /* Posición a la derecha */
}

/* Contenedor de logos */
.custom-small-container {
  display: flex;
  justify-content: center; /* Centra el logo dentro del contenedor */
  align-items: center;
}

.custom-small-image {
  width: 7vw;
  object-fit: contain;
}

/* Indicadores (dots) */
.custom-carousel-indicators {
  position: absolute;
  bottom: -30px; /* Bajamos los dots un poco más hacia abajo */
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 20px; /* Añadimos un poco de padding inferior */
}

.custom-dot {
  height: 7px;
  width: 7px;
  margin: 0 3px;
  background-color: #000;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  bottom: -3vw;
  position: relative;
}

.custom-dot.active {
  background-color: #d32f2f;
}

.footer-red1-line {
  background-color: #d32f2f;
  height: 1.8px;
  width: 25vw;
  left: 39vw;
  position: relative;
  margin-bottom: 1vw;
}

.image-container {
  position: relative; /* Necesario para la superposición */
  display: inline-block; /* Asegura que las imágenes estén en línea */
  width: 50%; /* Asegura que cada imagen ocupe la mitad del contenedor */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Ocupa todo el ancho de la imagen */
  height: 100%; /* Ocupa toda la altura de la imagen */
  background-color: black; /* Fondo negro para la superposición */
  opacity: 0; /* Inicialmente invisible */
  transition: opacity 0.3s ease; /* Suave transición */
  cursor: pointer; /* Cambia el cursor al pasar por encima */
}

.image-container:hover .overlay {
  opacity: 0.5; /* Muestra la superposición al pasar el mouse */
}

.custom-large-image {
  width: 100%; /* Asegura que la imagen grande se ajuste al contenido */
  height: auto; /* Mantiene la proporción de la imagen */
}

.logo-container {
  position: relative; /* Necesario para la superposición */
  display: inline-block; /* Asegura que los logos estén en línea */
}



/* Estilos para el logo-container */
.logo-container {
  position: absolute; /* Posicionamiento absoluto para cubrir más área */
 
  width: 100%; /* Hace el contenedor más ancho */
  height: 100%; /* Hace el contenedor más alto */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; /* Asegura que esté por encima del contenido */
}

/* Overlay para el logo-container */
.logo-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Cubre todo el ancho */
  height: 100%; /* Cubre toda la altura */
  background-color: black; /* Color de la superposición */
  opacity: 0; /* Inicialmente invisible */
  transition: opacity 0.3s ease; /* Transición suave */
  pointer-events: none; /* Permite hacer clic en el contenido debajo */
} /* Efecto hover en el logo-container */
.logo-container:hover::before {
  opacity: 0.5; /* Muestra la superposición al pasar el mouse */
}

/*SERVICIOS*/

.services-section {
  text-align: center; /* Centra el contenido */
  padding: 50px 0; /* Espaciado superior e inferior */
  background-color: #fff; /* Fondo suave */
}

.services-title {
  font-family: 'Raleway'; /* Fuente del título */
  font-weight: 600; /* Negrita */
  font-size: 2vw; /* Tamaño del título */
  margin-bottom: .5vw; /* Margen inferior */
  color: #54565A;
}

.services-content {
  display: flex; /* Usa flexbox para el contenido */
  justify-content: center; /* Centra el contenido */
  align-items: center; /* Centra verticalmente */
  text-align: left;
  margin-bottom: 30px; /* Margen inferior */
}

.services-subtitle {
  font-family: 'Raleway', sans-serif; /* Fuente del subtítulo */
  font-weight: 500; /* Negrita */
  font-size: 1.3vw; /* Tamaño del subtítulo */
  color: #EC2227; /* Color rojo */
  margin-right: 1vw; /* Espacio entre subtítulo y círculo */
  text-align: left;
  margin-left: -15vw;
}

.services-arrow-container {
  display: flex; /* Usa flexbox para el contenedor del círculo */
  justify-content: center; /* Centra el círculo */
  align-items: center; /* Centra verticalmente */
}



.full-width-image {
  width: 100%; /* Imagen que abarca todo el ancho */
  height: auto; /* Mantiene la proporción de la imagen */
  padding-left: 10vw;
  padding-right: 10vw;
}

.footer-gray4-line {
  background-color: #54565A;
  height: 1px;
  width: 34vw;
  margin-bottom: 1vw;
  left: 33vw;
  position: relative;
}

.contenedor-index {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: -5vw;
}

.item-index {
  color: #54565A;
  padding: 1vw;
  text-align: center;
  flex: 1;
  font-family: 'Caveat';
  font-size: 2vw;
  margin: 1vw;
} 
/* CONTACTO */


.contact-section {
padding-bottom: 5vw; 
margin-top: -5vw;
background-color: white; 
font-family: 'Montserrat', sans-serif; 
color: #333;
}

.contact-container {
display: flex;
justify-content: space-between;
max-width: 1200px;
margin: 0 auto;
}


.contact-info {
width: 40%;
color: #EC2227; 
font-size: 5vw;
padding-right: 40px;
}

.contact-info h2 {
color: #EC2227;
font-family: 'Raleway'; /* Fuente de título */
font-size: 3vw;
font-weight: 500;
margin-bottom: 5vw;
border-top: 2px solid #e4002b;
}

.contact-info h3 {
color: #EC2227;
font-family: 'Raleway'; /* Fuente de subtítulo */
font-size: 1.5vw;
font-weight: 500;
margin-bottom: 10px;
}

.contact-info p {
color: #54565A;
font-size: .9vw;
line-height: 1.6;
margin-bottom: 20px;
font-weight: 600;
}

/* Right section (Contact Form) */
.contact-form {
width: 50%;
}

.contact-form form {
display: flex;
flex-direction: column;
}

.form-group {
margin-bottom: 2.5vw;
}

.form-group input,
.form-group textarea {
width: 100%;
padding: 10px 0;
font-size: 16px;
border: none;
border-bottom: 2px solid #ccc; 
outline: none;
transition: border-color 0.3s ease;
font-family: 'Montserrat', sans-serif; /* Fuente de los campos */
margin-bottom: -2vw;
}

.form-group input:focus,
.form-group textarea:focus {
border-bottom: 2px solid #e4002b; /* Cambio de color en foco */
}

textarea {
height: 100px;
}

/* Botón de Enviar */
button {
background-color: #e4002b; /* Fondo rojo */
color: white;
border: none;
padding: 1vw 1.5vw;
font-size: 16px;
font-weight: 600;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
margin-left: auto; 

}

button:hover {
background-color: #b00022;
}

.arrow-circle {
border: 2px solid white; /* Borde blanco, sin relleno */
border-radius: 50%;
padding: 5px;
display: flex;
align-items: center;
justify-content: center;
margin-left: 10px;
}

.arrow-icon {
color: white; /* Flecha blanca */
font-size: 18px;
}

button:hover .arrow-circle {
border-color: #ccc; /* Cambio de color en hover */
transition: 0.3s ease;
}

.mandatory-field {
font-size: 12px;
color: #999;
margin-top: .1vw;
text-align: left;
margin-bottom: -1.5vw;
}

/* Iconos */
h3 span svg {
fill: #e4002b;
margin-left: 10px;
}







/* Responsive Design */
@media screen and (max-width: 768px) {



.image-container {
  overflow-x: auto; /* Habilitar el desplazamiento horizontal */
  width: 100vw; /* Asegurarse de que el contenedor tenga el ancho completo */
}

.responsive-image {
  width: 400%; /* Escalar la imagen al 300% */
  display: block; /* Asegurarse de que se muestre como bloque */
  min-width: 100%; /* Evitar que la imagen se reduzca demasiado */
}



.contact-section {
  padding: 10vw 5vw; /* Reducimos los márgenes para móviles */
  background-color: white; 
  font-family: 'Montserrat', sans-serif; 
  color: #333;
}

.contact-container {
  display: flex;
  flex-direction: column; /* Cambiamos a columna en lugar de fila */
  max-width: 100%;
  margin: 0 auto;
}

/* Información de contacto */
.contact-info {
  width: 100%; /* Ocupa todo el ancho en móviles */
  color: #EC2227; 
  font-size: 14px; /* Reducimos ligeramente la fuente */
  padding-right: 0; /* Eliminamos padding lateral en móviles */
}

.contact-info h2 {
  color: #EC2227;
  font-family: 'Raleway'; 
  font-size: 6vw; /* Tamaño adaptado para móviles */
  font-weight: 500;
  margin-bottom: 5vw;
  border-top: 2px solid #e4002b;
}

.contact-info h3 {
  color: #EC2227;
  font-family: 'Raleway'; 
  font-size: 4.5vw; /* Ajuste para móviles */
  font-weight: 500;
  margin-bottom: 4vw;
}

.contact-info p {
  color: #54565A;
  font-size: 4vw; /* Tamaño de fuente más pequeño para texto */
  line-height: 1.6;
  margin-bottom: 5vw; /* Aumentamos el margen inferior para mejorar la legibilidad */
  font-weight: 600;
}

/* Sección de formulario de contacto */
.contact-form {
  width: 100%; /* Ocupa todo el ancho */
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 6vw; /* Margen inferior más amplio en móviles */
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 2vw 0; /* Aumentamos el padding para móviles */
  font-size: 4vw; /* Ajustamos el tamaño de la fuente */
  border: none;
  border-bottom: 2px solid #ccc; 
  outline: none;
  transition: border-color 0.3s ease;
  font-family: 'Montserrat', sans-serif; 
}

textarea {
  height: 20vw; /* Ajustamos la altura del textarea */
}

/* Botón de enviar */
button {
  background-color: #e4002b; 
  color: white;
  border: none;
  padding: 3vw 4vw; /* Aumentamos el padding en móviles */
  font-size: 4vw; /* Ajustamos el tamaño de la fuente */
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0; /* Botón alineado a la izquierda en móviles */
  width: 100%; /* Alineamos el botón a toda la pantalla en móviles */
}

.arrow-circle {
  border: 2px solid white; 
  border-radius: 50%;
  padding: 3vw; /* Más espacio alrededor del ícono en móviles */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 3vw; /* Ajustamos el margen */
}

.arrow-icon {
  color: white; 
  font-size: 5vw; /* Aumentamos el tamaño del ícono para móviles */
}

.mandatory-field {
  font-size: 3vw; /* Ajustamos el tamaño de la fuente */
  color: #999;
  margin-top: .5vw;
  text-align: left;
  margin-bottom: 1vw;
}

  /* Inicio de estilos nav dinamico movil */
.nav-mobile {
  padding-top: 3vw;
  height: 15vw;
  transition: background-color 0.3s, transform 0.3s; 
}

.logo-mobile {
  width: 30%;
  height: auto;
  margin-left: 0vw; /* Agrega margen si es necesario para espaciado */
  margin-right: 2vw;
}
.navbar-toggler {
  margin-left: 0; /* Elimina el margen central */
  margin-right: auto; /* Empuja el botón hacia la izquierda */
  background-color: #e4002b; /* Evita el cambio de color */
  border: none; /* Elimina el borde por defecto */
  outline: none; /* Evita los bordes de enfoque */
  padding: 20px 10px;
  border-radius: 5px;
  transition: transform 0.2s ease-in-out, background-color 0.3s ease; /* Animación de escala y color */
  width: 8%;
}

.navbar-toggler:focus, 
.navbar-toggler:active {
  background-color: transparent; /* Asegura que no cambie a rojo en :focus o :active */
  outline: none;
  border: none;
  box-shadow: none; /* Elimina sombras de enfoque o clic */
}

.navbar-toggler-icon {
  background-image: none; /* Elimina la imagen SVG por defecto */
  background-color: white; /* Aplica un fondo blanco */
  width: 24px; /* Ajusta el tamaño del ícono */
  height: 3px;
  display: block;
  position: relative;
  transition: background-color 0.3s, transform 0.2s ease-in-out; /* Animación suave de color y escala */
}

.navbar-toggler-icon::before,
.navbar-toggler-icon::after {
  content: '';
  background-color: white; /* Barras blancas */
  width: 24px;
  height: 3px;
  display: block;
  position: absolute;
  left: 0;
  transition: background-color 0.3s; /* Transición para el cambio de color */
}

.navbar-toggler-icon::before {
  top: -7px;
}

.navbar-toggler-icon::after {
  top: 7px;
}

/* Animación de clic */
.navbar-toggler:active .navbar-toggler-icon {
transform: scale(0.6); /* Simula un efecto de "presionado" */
}

.btn-nav-estatico:focus i,
.btn-nav-estatico:active i {
transform: scale(0.6); /* Reduce el tamaño del ícono al presionar (simula el "clic") */
}

.barras-icon {
  background-image: none; /* Elimina la imagen SVG por defecto */
  background-color: rgb(182, 17, 17); /* Aplica un fondo blanco */
}

/* Cambiar color cuando se ha hecho scroll */
.text-black {
  background-color: white; /* Cambiar a negro (o el color que prefieras) */
}

.text-black::before,
.text-black::after {
  background-color: white; /* Cambiar a negro (o el color que prefieras) */
}

.bg-white {
  background-color: #fafafa; /* Color de fondo blanco */
}

.whatsapp-icon-mobile {
  width: 6vw;
  height: auto;
  margin-left: 25vw;
}

.whatsapp-icon-mobile-nav {
  width: 6vw;
  height: auto;
}

/* Desplazamiento al llegar a la segunda sección */
.moved {
  transform: translateY(16vw); /* Ajusta el valor para controlar cuánto baja */
}

/* Contenedor principal del carrusel */
.mobile-carousel {
position: fixed; /* Cambia position a fixed */
top: 0; /* Fija el carrusel en la parte superior */
left: 0; /* Asegura que esté alineado a la izquierda */
width: 100%; /* Ocupa el 100% del ancho de la pantalla */
height: 17vw; /* Ajusta la altura según tus necesidades */
background-color: #54565A; /* Fondo igual que en la versión desktop */
padding-top: 2vw; /* Espaciado interno superior */
z-index: 999; /* Asegura que esté por encima de otros elementos */
overflow: hidden; /* Evita el desbordamiento */
border-bottom: 10px solid #e4002b; 
}

.mobile-carousel-estatico {
left: 0; /* Asegura que esté alineado a la izquierda */
width: 100%; /* Ocupa el 100% del ancho de la pantalla */
height: 17vw; /* Ajusta la altura según tus necesidades */
background-color: #54565A; /* Fondo igual que en la versión desktop */
padding-top: 3vw; /* Espaciado interno superior */
overflow: hidden; /* Evita el desbordamiento */
border-bottom: 7px solid #e4002b; 

}

/* Estilo de los elementos del carrusel */
.mobile-banner-text {
font-size: 4vw;
color: #8e8f92ff;
font-family: 'Montserrat', sans-serif;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
margin: 0; /* Asegura que no haya márgenes adicionales */
margin-top: 2vw;
}

.mobile-icons-gray-icon {
width: 5vw;
height: auto;
margin-left: 1vw;
}

.icon-nav-space{
margin-bottom: -3vw;
}

.offcanvas {
position: fixed;
top: 0;
right: -100%; /* Oculto por defecto */
height: 100%;
width: 100%; /* Ancho del contenedor */
background-color: #EC2227;
color: white;
padding: 20px;
border-top: 2vw solid #EC2227;
transition: right 0.3s ease; /* Transición suave */
z-index: 1050; /* Asegúrate de que esté por encima de otros elementos */
}


.services-content {
  display: flex; /* Usa flexbox para el contenido */
  justify-content: center; /* Centra el contenido */
  align-items: center; /* Centra verticalmente */
  text-align: left;
  margin-bottom: -15px; /* Margen inferior */
}

.offcanvas.show {
right: 0; /* Mostrar el contenedor */
}

.offcanvas-header {
padding: 0;
margin-bottom: 20%;
text-align: left;
margin-left: 2%;
}

.offcanvas-header .btn-close {
filter: invert(1);
color: black !important; 
width: 8%;
height: auto;
text-align: center;
font-weight:bold ;
}

.offcanvas-body ul {
list-style-type: none;
padding: 0;
margin: 0;
}

.offcanvas-body ul li {
margin: 10px 0;
text-align: left;
}

.offcanvas-body ul li a {
color: rgba(255, 255, 255, 0.6);
text-decoration: none;
font-size: 28px;
font-family: 'Raleway', sans-serif; /* Fuente del subtítulo */
display: block;
padding: 5px 15px;
border-radius: 5px;
transition: background-color 0.3s;
}

.offcanvas-body ul li a:hover {
color: white;
}

.sub-menu {
  list-style-type: none; /* Eliminar el estilo de lista */
  padding: 0; /* Eliminar padding */
  margin: 0; /* Eliminar margen */
  margin-left: 12vw !important;
}

.sub-menu li {
  margin-top: -2vw!important;/* Margen entre las opciones */
  text-align: left; /* Alinear texto a la izquierda */
}

.sub-menu a {
  color: rgba(255, 255, 255, 0.6) !important;/* Color del texto de los enlaces */
  text-decoration: none; /* Sin subrayado */
  display: block; /* Hacer que el enlace ocupe todo el ancho */
  transition: background-color 0.3s; /* Transición suave para el hover */
}


.sub-menu li a:hover::before,
.sub-menu li a:focus::before,
.sub-menu li a:active::before {
  content: '>';
  position: absolute;
  left: 55px; /* Ajusta la posición a tu gusto */
  color: white; /* Color del símbolo > */
  margin-top: -2.5px;
}

.sub-menu li a:hover,
.sub-menu li a:focus,
.sub-menu li a:active {
  font-weight: bold; /* Aplica estilos adicionales si lo deseas */
  
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Estilos para el carrusel en móvil */
#carouselExampleMobile .carousel-item .banner {
width: 100vw;  /* Abarca el ancho completo de la pantalla */
height: 100vh; /* Abarca la altura completa del viewport (pantalla) */
object-fit: cover;  /* Hace que la imagen se recorte para llenar el área manteniendo la proporción */
}

/* Alineación del contenido dentro de las imágenes */
#carouselExampleMobile .overlay-content {
  position: absolute;
  top: 50%;
  margin-left: 30vw;
  transform: translate(-50%, -50%);
  text-align: left;
  
  width: 90%; /* Para que el contenido no se desborde en pantallas pequeñas */
  color: white; /* Asegúrate de que el texto sea visible */
  }

#carouselExampleMobile p{
  font-size: 4.2vw;
  max-width: 80vw;
  }


#carouselExampleMobile h2{
  margin-top: 1vw;
  font-size: 9vw;
  }

  #carouselExampleMobile .more-link, .small-arrow-circle{
    font-size: 4.3vw;
  }

/* Ajustes específicos para el logo dentro del carrusel móvil */
#carouselExampleMobile .logo2 {
width: 35vw; /* Cambia este valor según necesites */
height: auto; /* Mantén la proporción */
max-width: 100%; /* Asegúrate de que no exceda el contenedor */
margin: 0 auto; /* Centra el logo en su contenedor */
margin-bottom: 4vw;
margin-left: 17vw;

}




/* Indicadores del carrusel (puntitos) */
#carouselExampleMobile .carousel-indicators {
display: flex;
justify-content: center; /* Centra los puntitos horizontalmente */
position: absolute;
bottom: 20px;
width: 100%;
z-index: 10;
}

#carouselExampleMobile .carousel-indicators li {
  display: inline-block;
  width: 3vw; /* Tamaño ligeramente mayor para mejor visibilidad */
  height: 3vw; /* Tamaño ligeramente mayor para mejor visibilidad */
  background-color: #4D4D4D; /* Color gris oscuro para los puntitos inactivos */
  border-radius: 50%; /* Forma circular */
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Transición suave al cambiar de color */
  }

#carouselExampleMobile .carousel-indicators li.active {
background-color: #EC2227; /* Color rojo para el puntito activo */
}

/* Efecto al pasar el ratón sobre los puntitos */
#carouselExampleMobile .carousel-indicators li:hover {
background-color: #EC2227; /* Resalta al pasar el ratón */
}

.arrow-circle-container {
display: flex; /* Usar flex para centrar el contenido */
align-items: center; /* Centra verticalmente */
justify-content: center; /* Centra horizontalmente */
width: 50vw; /* Ajusta el tamaño según sea necesario */
height: 7vw; /* Ajusta el tamaño según sea necesario */
border-radius: 50%; /* Bordes redondeados para hacer un círculo */
border: 3px solid white; /* Borde blanco */
background-color: transparent; /* Sin relleno */
margin: 0 15px; /* Espacio horizontal para las flechas */
}

.footer-gray2-line {
background-color: #ffffff;
height: 3px;
width: 60vw;
margin-left: 20vw;
}



/* Estilo general para la sección de información */
.info-section {
background-color: #f8f8f8;
padding: 0;
}

/* Contenedor flexible para centrar el contenido */
#container-fluid2 {
  display: flex;
  flex-direction: column; /* Cambia a columna para móvil */
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  
  }

/* Estilo del rectángulo */
.rectangle {
display: flex;
flex-direction: column; /* Cambia a columna para móvil */
width: 100%;
max-width: 1200px; /* Puedes mantener esto si quieres un límite en pantallas grandes */
background-color: #54565A; /* Fondo gris oscuro */
margin-top: 0; /* Reduce el margen para pantallas más pequeñas */

}

/* Estilo para la imagen */
.image-side {
width: 100%; /* Se asegura que la imagen ocupe todo el ancho */
}

.image-side img {
display: block;
width: 100%;
object-fit: cover; /* Asegura que la imagen se ajuste correctamente */
margin: 0;
border: none; /* Sin bordes redondeados */
}

/* Estilo para el texto */
.text-side {
padding: 20px; /* Ajusta el padding para móviles */
color: white;
position: relative; /* Para que la flecha se posicione dentro de este contenedor */
}

.text-side h2 {
font-size:8vw; /* Tamaño más pequeño para móvil */
font-weight: 900;
margin-bottom: 5px;
margin-top: 1px;
font-family: 'Helvetica';
}

.text-side h3 {
font-size: 5vw; /* Tamaño relativo para móvil */
font-weight: 200;
margin-bottom: 2vw;
}

.text-side p {
font-size: 4vw; /* Tamaño relativo para móvil */
line-height: 1.6;
margin-bottom: 15px;
font-weight: 100;
font-family: 'Raleway';
}

/* Estilo para el botón "más información" */
.more-info {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0; /* Sin márgenes */
}

.more-info img {
width: 50px; /* Ajusta el tamaño de la imagen del botón */
height: 60px; /* Ajusta el tamaño de la imagen del botón */
display: inline-block;
}

/* Estilo para la línea del pie de página */
.footer-gray3-line {
background-color: #ffffff;
height: 2px;
width: 80%; /* Ajusta el ancho para que se vea bien en móvil */
max-width: 300px; /* Límite de ancho */
margin-right: 6vw;
}

.services-title {
  font-family: 'Raleway'; /* Fuente del título */
  font-weight: 500; /* Negrita */
  font-size: 7.5vw; /* Tamaño del título */
  margin-bottom: 20px; /* Margen inferior */
  color: #54565A;
  text-align: left;
  margin-left: 3vw;
  
}

.services-subtitle {
  font-family: 'Raleway', sans-serif; /* Fuente del subtítulo */
  font-weight: 500; /* Negrita */
  font-size: 5.5vw; /* Tamaño del subtítulo */
  color: #EC2227; /* Color rojo */
  margin-right: 1vw; /* Espacio entre subtítulo y círculo */
  text-align: left;
  margin-left: -20vw;
  margin-top: -1vw;
}

.fr{
  width: 4vw;
}


/* Contenedor principal */
.custom-product-carousel {
  text-align: left;
  padding-bottom: 10vw;
  padding-top: 5vw;
  background-color: #f8f8f8;
  }
  
  .custom-product-carousel h2 {
  font-family: 'Raleway';
  font-weight: 500;
  margin-left: 3vw;
  font-size: 7.5vw; /* Tamaño más pequeño para móviles */
  margin-bottom: 20px;
  color: #d32f2f;
  }
  
  /* Estilo del carrusel */
  .custom-carousel {
  position: relative;
  width: 100%;
  max-width: 100%; /* Ocupa todo el ancho en móvil */
  margin: 0 auto;
  margin-bottom:5vw;
  }
  
  /* Imágenes grandes al fondo */
  .custom-carousel-back {
  display: flex;
  overflow: hidden;
  }
  
  .custom-carousel-back-movil {
    display: flex;
    overflow: hidden;
    }

  .custom-large-container {
  display: flex;
  flex-direction: column; /* Cambia a columna para móviles */
  flex-shrink: 0;
  width: 100%;
  }
  
  .custom-large-image {
  width: 100%; /* Asegura que las imágenes ocupen todo el ancho */
  object-fit: cover;
  margin: 0; /* Sin margen entre imágenes */
  border-radius: 0; /* Elimina bordes redondeados */
  }
  
  /* Imágenes pequeñas al frente */
  .custom-carousel-front {
  position: absolute;
  top: 60vw; /* Ajustamos la posición un poco más hacia abajo */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center; /* Centra los logos dentro del contenedor */
  align-items: center;
  background-color: white; /* Fondo blanco detrás de los logos */
  padding: 8px 15px; /* Ajustamos el padding para que sea más delgado en los lados */
  height: 38vw; /* Ajustamos la altura */
  box-shadow: 0px 0px 15px rgba(0,0,0,0.2);
  width: fit-content; /* Ajusta el ancho automáticamente al contenido */
  }
  
  .custom-small-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Permite que los logos se envuelvan en móviles */
  
  
  }
  
  .custom-small-image {
  width: 200px; /* Tamaño más pequeño para móviles */
  margin: 0 5px; /* Espaciado reducido entre logos */
  object-fit: contain;
  }
  
  /* Indicadores (dots) */
  .custom-carousel-indicators {
  position: absolute;
  bottom: -40px; /* Bajamos los dots un poco más hacia abajo */
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 20px; /* Añadimos un poco de padding inferior */
  }
  
  .custom-dot-movil {
  height: 8px;
  width: 8px;
  margin: 0 3px;
  background-color: #000;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  bottom: -37vw;
  position: relative;
  margin-bottom: 10vw;
  
  }
  
  .custom-dot-movil.active {
  background-color: #d32f2f;
  }
  
  .footer-red1-line {
  background-color: #d32f2f;
  height: 2px;
  width: 100%; /* Ajusta el ancho para que se vea bien en móvil */
  max-width: 350px; /* Límite de ancho */
  left: 45%;
  transform: translateX(-50%); /* Centra la línea horizontalmente */
  position: relative;
  margin: 0; /* Margen automático para centrar */
  margin-top: -3vw;
  }

  .footer-gray4-line {
    background-color: #54565A;
    height: 2.2px;
    width: 100%; /* Ajusta el ancho para que se vea bien en móvil */
    max-width: 350px; /* Límite de ancho */
    margin-bottom: 1vw;
    left: 45%;
    transform: translateX(-50%); /* Centra la línea horizontalmente */
    position: relative;
    margin-top: 15vw;
  }
  
  .image-container {
  position: relative; /* Necesario para la superposición */
  display: inline-block; /* Asegura que las imágenes estén en línea */
 
  }
  
  .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Ocupa todo el ancho de la imagen */
  height: 100%; /* Ocupa toda la altura de la imagen */
  background-color: black; /* Fondo negro para la superposición */
  opacity: 0; /* Inicialmente invisible */
  transition: opacity 0.3s ease; /* Suave transición */
  cursor: pointer; /* Cambia el cursor al pasar por encima */
  }
  
  .image-container:hover .overlay {
  opacity: 0.5; /* Muestra la superposición al pasar el mouse */
  }
  
  .custom-large-image {
  width: 100%; /* Asegura que la imagen grande se ajuste al contenido */
  height: auto; /* Mantiene la proporción de la imagen */
  }
  
  .logo-container {
  position: relative; /* Necesario para la superposición */
  display: inline-block; /* Asegura que los logos estén en línea */
  }
  
  .logo-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Ocupa todo el ancho de la imagen */
  height: 100%; /* Ocupa toda la altura de la imagen */
  background-color: black; /* Fondo negro para la superposición */
  opacity: 0; /* Inicialmente invisible */
  transition: opacity 0.3s ease; /* Suave transición */
  cursor: pointer; /* Cambia el cursor al pasar por encima */
  }
  
  .logo-container:hover .overlay {
  opacity: 0.5; /* Muestra la superposición al pasar el mouse */
  }

  .contenedor-index {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: -5vw;
  }

  .item-index {
    color: #54565A;
    padding: 1vw;
    text-align: center;
    flex: 1;
    font-family: 'Caveat';
    font-size: 10vw;
}

/* Diferentes márgenes para cada item */
.item-index:nth-child(1) {
    margin-right: 70vw; /* Primer item */
    margin-left: 8vw;
}

.item-index:nth-child(2) {
    margin-right: 70vw; /* Segundo item */
}

.item-index:nth-child(3) {
    margin-right: 65vw; /* Tercer item */
}



}


/*NAVS*/

.navbar {
display: flex;
justify-content: space-between;
align-items: center;
transition: background-color 1s, top 0.3s ease-in-out, opacity 0.5s ease;
height: 4vw;
opacity: 0;
border-top: 0.3vw solid #EC2227;
padding-bottom: 4vw;
}

.navbar-visible {
opacity: 1; /* Hace visible el navbar al cargar */
}

.loaded .navbar {
opacity: 1; /* Puedes mantenerlo por si necesitas esta lógica */
}

.navbar-brand {
margin-left: 0.5%; /* Ajusta el margen izquierdo del logo */
margin-right: 0%;
}

.logo {
width: 9vw;
height: auto; /* Ajusta el tamaño del logo */
transition: opacity 0.3s ease; /* Transición suave */
}

/* Solo para ocultar el logo no visible */
.logo:not(:last-child) {
display: none; /* Oculta el logo por defecto */
}

.navbar-brand img {
display: block; /* Asegura que las imágenes no tengan espacio debajo */
}


.nav-links {
flex-grow: 1; /* Para que ocupe el espacio central */
display: flex;
justify-content: center;
align-items: center;
}

.nav-link {
margin: 0 0.2vw; /* Margen horizontal para los enlaces */
color: white;
font-size: 1.1vw;
font-family: 'Montserrat', sans-serif;
}

.nav-links a:hover {
color: #EC2227; /* Cambia el color del texto al pasar el mouse (ejemplo: dorado) */
text-decoration: none;
}

.whatsapp-icon {
width: 1.5vw; /* Ajusta el tamaño del icono */
height: auto;
}


.moved-down {
top: 3.5vw;
}

.scrolled {
background-color: #fafafa; /* Color de fondo cuando se desplaza */
}

.scrolled .nav-link {
color: black; /* Cambia el color del texto a negro cuando se desplaza */
}

/* Contenedor del banner */
.main-banner {
width: 100%; /* Ocupa el 100% del ancho de la ventana */
margin: 0; /* Elimina el margen alrededor del contenedor */
padding: 0; /* Elimina el padding alrededor del contenedor */
overflow: hidden; /* Evita cualquier desbordamiento */
z-index: 1000; 
position: relative; 
}

/* Imagen del banner */
.banner-image {
width: 100%; /* Hace que la imagen ocupe el 100% del ancho del contenedor */
height: auto; /* Mantiene la proporción de la imagen */
display: block; /* Elimina el espacio debajo de la imagen */
}

.example-section{
width: 100%; /* Hace que la imagen ocupe el 100% del ancho del contenedor */
height: 300vw; /* Mantiene la proporción de la imagen */
}

.gray-banner {
position: fixed; /* Mantiene el contenedor en la parte superior */
top: 0; /* Fija el contenedor en la parte superior */
width: 100%; /* Ocupa el 100% del ancho de la ventana */
background-color: #54565A; /* Puedes ajustar el color de fondo */
z-index: 999; /* Asegura que esté por encima de otros elementos */
height: 3.5vw;
}

.second.text-center {
text-align: center; /* Alinea el texto en el centro */
}

.row {
justify-content: center; /* Centra las columnas horizontalmente */
}

.icons-gray-icon {
width: 1.4vw; /* Ajusta el tamaño del icono */
height: auto;
margin-right: 1vw;
}


.icons-gray-icon1 {
  width: 1vw; /* Ajusta el tamaño del icono */
  height: auto;
  margin-right: 1vw;
  }

  .icons-gray-icon2 {
    width: 1.2vw; /* Ajusta el tamaño del icono */
    height: auto;
    margin-right: 1vw;
    }

.banner-text{
font-size: 1vw;
color: #8d8e92ff;
margin-top: 0.9vw;
font-weight: 100;
font-family: 'Montserrat', sans-serif;
}



/* Estilos para el submenú desplegable */
.dropdown-menu {
display: none; /* Oculta el submenú por defecto */
position: absolute;
background-color: #54565A; /* Fondo blanco para el submenú */
list-style: none; /* Elimina los puntos de lista */
padding: 1vw;
margin: 0;
box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Sombra */
z-index: 1000; /* Asegura que esté por encima de otros elementos */
width: 15vw;
border-bottom: 0.3vw solid #EC2227;
border-radius: 0; 
text-decoration: none;
color: white; 
}

.dropdown:hover .dropdown-menu {
display: block; /* Muestra el submenú al hacer hover */
}

/* Estilos para los enlaces dentro del submenú */
.dropdown-menu li {
width: 100%; /* Ocupa el 100% del ancho del contenedor */
}
/* Estilos para los enlaces dentro del submenú */
.dropdown-menu li a {
display: block;
padding: 0.1vw 1vw; /* Espaciado dentro de cada opción */
color: #7B7D80; /* Color del texto */
text-decoration: none; /* Elimina subrayado */
font-size: 0.9vw;
font-family: 'Montserrat', sans-serif;
position: relative; /* Necesario para posicionar el pseudo-elemento */
}

/* Pseudo-elemento que aparece al hacer hover */
.dropdown-menu li a:hover::before {
content: '>'; /* Símbolo que aparece a la izquierda */
position: absolute;
left: 0vw; /* Ajusta esta posición según tu diseño */
color: white; /* Color del símbolo */
}

/* Estilo para el enlace al hacer hover */
.dropdown-menu li a:hover {
color: white; /* Color de fondo al pasar el mouse */
}

/* Fin de estilos nav dinamico escritorio */


/* Inicio de estilos n estatico escritorio */
.menu-estaico {
width: 100%;
height: 4vw;
padding-top: 0.5vw;
padding-bottom: 0.5vw;
position: fixed;
top: 0;
background-color: #fafafa;
}

.navbar-brand {
margin-right: 1%; /* Ajusta el espacio entre el logo y los enlaces */
}

.nav-links {
margin: 0 auto; /* Centramos los enlaces */
text-align: center;
}

.nav-link-estatico {
margin: 0 2vw; /* Ajusta el espacio entre los enlaces */
color: black;
font-size: 1vw;
font-family: 'Montserrat', sans-serif;
text-decoration: none;
}

.nav-links a:hover {
color: #EC2227;
}

.whatsapp-icon {
width: 2.5vw; /* Ajusta el tamaño de la imagen de WhatsApp según sea necesario */
}

.logo-estatico {
width: 8vw; /* Ajusta el tamaño del logo según sea necesario */
height: auto;
}


.separation{
height: 3vw;
}

.bannertoggler:active {
transform: scale(0.7); /* Efecto de reducción al presionar */
box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Sombra al presionar */
}


/* Fin de estilos n estatico escritorio */


/* Inicio de estilos b estatico escritorio */

.custom-gray-banner {
width: 100%; /* Ocupa el 100% del ancho de la ventana */
background-color: #54565A; /* Puedes ajustar el color de fondo */
height: 3.5vw;
}

.custom-second.custom-text-center {
text-align: center; /* Alinea el texto en el centro */
}

.custom-row {
justify-content: center; /* Centra las columnas horizontalmente */
}

.custom-icons-gray-icon {
width: 1.4vw; /* Ajusta el tamaño del icono */
height: auto;
margin-right: 1vw;
}

.custom-icons-gray-icon1 {
  width: 1vw; /* Ajusta el tamaño del icono */
  height: auto;
  margin-right: 1vw;
  }

  
  .custom-icons-gray-icon2 {
    width: 1.2vw; /* Ajusta el tamaño del icono */
    height: auto;
    margin-right: 1vw;
    }
    

.custom-banner-text {
font-size: 1vw;
color: #8d8e92ff;
margin-top: 0.9vw;
font-family: 'Montserrat', sans-serif;
}
/* fin de estilos b estatico escritorio */

.example-section{
width: 100%; /* Hace que la imagen ocupe el 100% del ancho del contenedor */
height: 300vw; /* Mantiene la proporción de la imagen */
}

/* SERVICES INDEX */

/* Sección de Servicios */
.servicios-desktop {
  margin-top: -5vw;
  padding-left: 14vw;
  padding-right: 14vw;
}

.servicios-desktop-2 {
  margin-top: 0;
  padding-left: 14vw;
  padding-right: 14vw;
  margin-bottom: 3%;
}

.servicios-desktop p{
  font-size: 2.5vw;
  color: #EC2227;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
}

.container-servicio {
  display: flex; /* Habilita flexbox para alinear los elementos horizontalmente */
  justify-content: space-between; /* Distribuye el espacio horizontalmente */
  align-items: left; /* Alinea verticalmente las divs en el centro del contenedor */
  background-color: #54565A; /* Color de fondo de la div principal */
  
}

/* Ajustar el ancho de la primera y segunda div */
.box-servicio-1 {
  width: 25%; /* Ancho de la primera div interna (ajústalo según tu diseño) */
}

.box-servicio-2 {
  width: 100%; /* Ancho de la segunda div interna (ajústalo según tu diseño) */
  text-align: left; /* Centra el texto dentro de cada div */
}

/* Ajustar el ancho de la primera y segunda div */
.box-servicio-3 {
  width: 50%; /* Ancho de la primera div interna (ajústalo según tu diseño) */
}

.box-servicio-4 {
  width: 50%; /* Ancho de la segunda div interna (ajústalo según tu diseño) */
  background-color: #636569;
}

.box-servicio-2 .line-nosotros-2{
  width: 45%;
  margin-top: 3%;
}

.box-servicio-2 h2 , .box-servicio-2 .line-nosotros-2 {
  color: white;
  margin-left: 5%;
  font-family: 'Raleway', sans-serif;
  font-size: 2.2vw;
}
.box-servicio-2 p,
.box-servicio-3 p,
.box-servicio-4 p{
  color: white;
  margin-left: 5%;
  margin-right: 5%;
  font-family: 'Raleway', sans-serif;
  font-size: 0.9vw;
}

.box-servicio {
  color: #333; /* Color del texto dentro de cada div */
  padding: 0%;
}

.servicio-img{
  width: 100%;
  height: auto;
}
.banner-servicios {
  text-align: center; /* Centra horizontalmente el contenido dentro del contenedor */
  padding-top: 3vw;
  padding-bottom: 6vw;
  background-color: #EC2227;
}

.contenedor-servicios-valores {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: -2vw;
}

.itemservicios-valores {
  color: #FEFEFE;
  padding: 1vw;
  text-align: center;
  flex: 1;
  font-family: 'Caveat';
  font-size: 2vw;
  margin: 1vw;
} 

.banner-img-servicios {
  width: 100%; /* Asegura que la imagen no exceda el ancho del contenedor */
  height: auto; /* Mantiene la proporción de la imagen */
  padding-left: 10vw;
  padding-right: 10vw;
}

.box-servicio-3 h2 , 
.box-servicio-4 h2 {
  color: white;
  margin-left: 5%;
  margin-right: 5%;
  font-family: 'Raleway', sans-serif;
  font-size: 1.8vw;
}

.box-servicio-3 .line-nosotros-2,
.box-servicio-4 .line-nosotros-2{
  width: 30%;
  margin-top: 5%;
  margin-left: 5%;
}
.footer-broder-mobile{
  border-width: 1px;
  border-style: solid;
  border-color: 54565A;
}

@media screen and (max-width: 768px) {
  .servicios-desktop, .servicios-desktop-2 {
    padding-left: 0; /* Ajusta el padding lateral */
    padding-right: 0;
    margin-top: 10vw;
    text-align: left; /* Centra el texto en móvil */
  }

  .servicios-desktop p, .servicios-desktop-2 p {
    font-size: 5vw; /* Aumenta el tamaño del texto para móvil */
    color: #EC2227;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
  }

  .servicios-desktop .line-nosotros {
    margin-left: 10%;
  }
  .container-servicio {
    flex-direction: column; /* Cambia la disposición de las cajas a columna */
    align-items: center; /* Centra las cajas en pantalla móvil */
  }

  .box-servicio {
    width: 100%; /* Las cajas toman todo el ancho en móvil */
    margin-bottom: 0; /* Añade espacio entre las cajas */
  }

  .box-servicio img {
    width: 100%; /* Las imágenes se ajustan al ancho del contenedor */
    height: auto; /* Mantén la proporción de las imágenes */
  }

  .box-servicio-2, .box-servicio-3, .box-servicio-4 {
    text-align: left; /* Centra el texto */
  }

  .margin-title-servicios{
    margin-left : 10vw;
    font-size: 2rem !important;
  }

  .box-servicio-2 h2, 
  .box-servicio-3 h2, 
  .box-servicio-4 h2 {
    font-size: 5.5vw; /* Ajusta el tamaño de los encabezados */
    margin-top: 1rem;
    color: white;
    font-family: 'Raleway', sans-serif;
    margin-left : 10vw;
    font-weight: 500;
  }

  .box-servicio-2 p, 
  .box-servicio-3 p, 
  .box-servicio-4 p {
    font-size: 4vw; /* Ajusta el tamaño de los párrafos */
    line-height: 1.4;
    color: white;
    padding: 0 5%;
    text-align: justify; /* Justifica el texto */

  }

  .box-servicio-2 .line-nosotros-2, 
  .box-servicio-3 .line-nosotros-2, 
  .box-servicio-4 .line-nosotros-2 {
    width: 80%; /* Ajusta el ancho de las líneas */
    margin: 0 auto 1rem auto; /* Centra las líneas */
    margin-top: 2rem; /* Añade el margen superior */
  }

  .banner-servicios {
    text-align: center;
    padding: 0;
    margin-top: 2rem;
  }

  .banner-img-servicios {
    width: 100%; /* Asegura que la imagen del banner ocupe el 100% del contenedor */
    height: auto;
  }
  .touch-indicator-servicios {
    position: absolute;
    top: 50%;
    left: 100%;
    width: 60px;
    height: 60px;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: touchEffectServicios 2s forwards infinite; /* Duración de 2 segundos, repetición infinita */
    animation-delay: 2s; /* Espera de 6 segundos antes de la próxima ejecución */
    opacity: 0;
    animation-timing-function: ease-in-out;
  }
  
  .second {
    animation: touchEffectSecondServicios 2s forwards infinite; /* Duración de 2 segundos, repetición infinita */
    animation-delay: 6s; /* Espera de 6 segundos antes de la próxima ejecución */
    animation-timing-function: ease-in-out;
    opacity: 0;
  }
  
  @keyframes touchEffectServicios {
    0% {
      left: 100%;
      opacity: 0;
    }
    50% {
      left: 50%;
      opacity: 1;
    }
    100% {
      left: 0%;
      opacity: 0;
    }
  }
  
  @keyframes touchEffectSecondServicios {
    0% {
      left: 100%;
      opacity: 0;
    }
    50% {
      left: 50%;
      opacity: 1;
    }
    100% {
      left: 0%;
      opacity: 0;
    }
  }
  
}





/* NOSOTROS INDEX */

/* Sección de "Nosotros" */
.nosotros-section{
  margin-top: -5vw;
  padding-left: 14vw;
}

.nosotros-section p ,
.nosotros-section-3 p {
   font-size: 2.5vw;
   color: #EC2227;
   font-family: 'Raleway', sans-serif;
   font-weight: 500;
}

.line-nosotros {
  height: 0.1vw; /* Altura de la línea */
  background-color: #EC2227; /* Color de la línea */
  width: 38%; /* Ancho de la línea */
}

.line-nosotros-2 {
  height: 0.2vw; /* Altura de la línea */
  background-color: white; /* Color de la línea */
  width: 50%; /* Ancho de la línea */
}

.sub-section-nosotros .line-nosotros-2{
  width: 30%;
}
.nosotros-section-2{
  padding-left: 14vw;
  padding-right: 14vw;
  margin-bottom: 2vw;
}

.main-container-nosotros {
  width: 100%; /* Ancho del contenedor principal */
  margin: 0 auto; /* Centrado automático */
  padding: 0;
}

.main-section-nosotros {
  display: flex; /* Flexbox para alinear los contenedores uno al lado del otro */
  background-color: #54565A; /* Color de fondo de la sección principal */
  margin-bottom: 0; /* Elimina espacio debajo de la sección principal */
  text-align: center;
  align-items: center; /* Centra verticalmente los elementos */
}

.img-container-nosotros {
  flex: 0.5; /* Hace que la imagen ocupe un espacio proporcional */
  margin: 0;
}

.main-image-nosotros {
  width: 100%; /* Hace que la imagen ocupe el 100% del espacio del contenedor */
  height: auto; /* Mantiene la proporción de la imagen */
}

.text-container-nosotros {
  flex: 1.5; /* Hace que el texto ocupe el otro espacio proporcional */
  text-align: left; /* Alinea el texto a la izquierda */
  padding-left: 3vw;
  padding-right: 2vw;
  padding-bottom: 5%;
}

.sub-container-nosotros {
  display: flex; /* Flexbox para alinear los sub-contenedores uno al lado del otro */
  margin: 0; /* Elimina los márgenes */
  box-shadow: 0 -0.1vw 0.5vw rgba(0, 0, 0, 0.3); /* Sombra solo en el borde superior */

}

.sub-section-nosotros {
  background-color: #d9d9d9; /* Color de fondo de los contenedores secundarios */
  padding: 2%; /* Espaciado interno */
  width: 50%; /* Ancho de cada sub-contenedor */
  text-align: left; /* Centrar el texto */
  font-size: 16px; /* Tamaño de fuente */
  margin: 0; /* Elimina margen entre los contenedores */
}

.sub-section-nosotros:first-child {
  border-left: none; /* Elimina la línea divisoria del primer contenedor */
}

.text-container-nosotros p,
.text-container-nosotros h3,
.sub-section-nosotros h2,
.sub-section-nosotros p {
  color: white;
  font-family: 'Raleway', sans-serif;
}
.text-container-nosotros h2{
  color: white;
  font-family: 'Archivo Black Regular', sans-serif;
}
.text-container-nosotros p {
  font-size: 1vw;
  line-height: 1.3;
  font-weight: 100;
  margin-bottom: 2%;
}

.text-container-nosotros h2 {
  font-size: 2.2vw;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 1vw;
}

.text-container-nosotros h3 {
  font-size: 1.5vw;
  font-weight: 400;
  margin-bottom: 2vw;
}

.text-container-nosotros h2,
.text-container-nosotros h3 {
  line-height: 1;
}

.sub-section-nosotros:nth-child(1) {
  background-color: #636569; /* Color para la primera sección */
}

.sub-section-nosotros:nth-child(2) {
  background-color: #737478; /* Color para la segunda sección */
}
.sub-section-nosotros p {
    font-size: 1vw;
    text-align: justify;

}

.sub-section-nosotros h2 {
  margin-top: 2%;
  font-size: 1.5vw;
}

.nosotros-section-3 {
 text-align: center;
}
.nosotros-section-3 p {
  text-align: center;
  margin-left: 0%;
}

.nosotros-section-3 .line-nosotros{
  margin-left: 40.2%;
}

.nuestros-valores-img {
  width: 80%; /* Imagen que abarca el 80% del ancho del contenedor */
  height: auto; /* Mantiene la proporción de la imagen */
  margin-top: 2vw; /* Margen superior para la imagen */
  margin-bottom: 8vw;
  display: block;
  margin-left: auto;
  margin-right: auto; /* Esto centra la imagen horizontalmente */
}

/* Producto INDEX */

/* Sección de "producto" */

.productos-main-section {
  display: flex;
  justify-content: center; /* Centrar horizontalmente */
  align-items: center;     /* Centrar verticalmente */
  padding: 0%;
  margin-top: -10%;
}

.productos-main-section .primer-div {
  flex: 1;
  text-align: center;        /* Contenido alineado a la derecha */
  padding: 1%;
}

.productos-main-section .segundo-div {
  flex: 1;
  text-align: left;         /* Contenido alineado a la izquierda */
  padding: 2%;
  padding-left: 0;
  margin-left: -2vw;
}

.productos-main-section .line-nosotros{
  width: 61.5%;
  margin-left: 33%;
  margin-top: 1%;
}

.logo-producto-section{
  width: 30%;
  height: auto;
  margin-left: -4%;
}

.primer-div h2{
  font-size: 2rem;
  color: #e4002b;
  font-family: 'Raleway', sans-serif;
  text-align: left;
  margin-top: 1%;
  padding-left: 33%;
}

.primer-div p{
  font-size: 0.9vw;
  color: black;
  font-family: 'Raleway', sans-serif;
  text-align: left;
  padding-left: 33%;
  line-height: 1.1vw;
}

.banner-producto-section{
  width: 70%;
  height: auto;

}

.card-producto{
  display: flex;
  justify-content: center; /* Centrar horizontalmente */
  align-items: center;     /* Centrar verticalmente */
  padding-left: 0%;
  padding-right: 0%;
  padding-top: 2%;
  padding-bottom: 2%;
  margin-top: 1%;
}

.primer-div-card-producto {
  flex: 0.7; 
  text-align: center; 
  padding-left: 10%;
}

.segundo-div-card-producto {
  flex: 1.3; 
  padding: 10px;
  text-align: center; 
}

.producto-section {
  width: 65%;
  height: auto;
}

.card-producto-side-rigth{
  display: flex;
}

.div-slide-producto{
  flex: 1.5; 
  text-align: left;
  align-items: start;
  padding-right: 0%;
}

.div-slide-producto-2{
  flex: 0.5; 
  padding-top: 0.5vw;
  text-align: left;
  align-items: start;
}
.linea-negra-productos{
  border: none;           /* Quita cualquier borde predeterminado */
  border-top: 0.15vw solid black;  /* Crea una línea negra */
  margin-top: -0.8vw;
  width: 95%;
  margin-left: 1.5vw;
}

.div-slide-producto p{
  margin-left: 2vw;
  font-family: 'Montserrat', sans-serif;
}

.div-slide-producto p:nth-of-type(1) {
  font-size: 1.3vw;
}

.div-slide-producto p:nth-of-type(2) {
  margin-top: 1.2vw; /* O cualquier estilo que desees aplicar */
  font-size: 1.2vw;
}

.div-slide-producto p:nth-of-type(3) {
  font-size: 1.2vw;
  margin-top: -0.8vw;
}

.div-slide-producto p:nth-of-type(4) {
  margin-top: 1.4vw; /* O cualquier estilo que desees aplicar */
  font-size: 1.2vw;
  
}

.div-slide-producto p:nth-of-type(5) {
  font-size: 1.2vw;
  margin-top: -0.8vw;
}

.btn-producto{
  background-color: #e4002b; /* Color de fondo */
  color: white;              /* Color del texto */
  border: none;              /* Sin borde */
  padding: 0.5vw 1vw;        /* Espaciado interno */
  font-size: 0.7vw;           /* Tamaño de la fuente */
  cursor: pointer;           /* Cambia el cursor a mano */
  transition: background-color 0.3s ease; /* Transición suave al hacer hover */
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
}
.btn-producto:hover {
  background-color: #b30022; /* Color más oscuro al hacer hover */
  text-decoration: none;
  color: white;
}

.contenedor-valores {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 4vw;
  padding-right: 4vw;
  margin-bottom: -2vw;
}

.itemvalores {
  color: #54565A;
  padding: 1vw;
  text-align: center;
  flex: 1;
  font-family: 'Caveat';
  font-size: 2vw;
} 
/* Responsivo */
@media screen and (max-width: 768px) {
  .nosotros-section {
    padding-left: 10vw; /* Ajusta el padding lateral */
    padding-right: 10vw; /* Ajusta el padding lateral */
    margin-top: 2vw; /* Elimina el margen superior */
    text-align: left;
  }

  .nosotros-section-3 p{
    font-size: 5vw;
  }

  .nosotros-section p {
    font-size: 2rem;
  }
  .nosotros-section-2 {
    padding-left: 0; /* Ajusta el padding lateral */
    padding-right: 0; /* Ajusta el padding lateral */
  }

  .main-section-nosotros {
    flex-direction: column; /* Cambia a columna en pantallas pequeñas */
    align-items: center; /* Centra los elementos */
  }

  .img-container-nosotros {
    width: 100%; /* Ancho completo para la imagen */
    margin-bottom: 1rem; /* Espacio inferior entre la imagen y el texto */
  }

  .main-image-nosotros {
    max-width: 100%; /* La imagen no excederá el ancho del contenedor */
    height: auto; /* Mantiene la proporción */
  }

  .text-container-nosotros {
    padding: 0; /* Elimina el padding lateral */
    text-align: left; /* Centra el texto */
  }

  .text-container-nosotros p {
    font-size: 1.2rem;
    text-align: justify;
    padding: 5%;
    line-height: 1.5;
  }

  .text-container-nosotros h3 {
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-left: 6vw;
  }

  .sub-container-nosotros {
    flex-direction: column; /* Cambia a columna para sub-secciones */
    margin: 0; /* Elimina márgenes */
    box-shadow: none; /* Elimina sombra en móvil */
  }

  .sub-container-nosotros .sub-section-nosotros:first-child {
    margin-top: 3vw; /* Ajusta el valor según tus necesidades */
}

  .sub-section-nosotros {
    width: 100%; /* Ancho completo para cada sub-sección */
    margin-bottom: 20px; /* Espacio entre sub-secciones */
    padding: 2rem; /* Espaciado interno */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Añade una sombra ligera */
  }

  .sub-section-nosotros h2 {
    font-size: 2rem; /* Aumenta el tamaño de fuente para encabezados */
    margin-top: 1rem; /* Margen superior para los encabezados */
  }
  
  .text-container-nosotros h2{
    font-size: 2rem; /* Aumenta el tamaño de fuente para encabezados */
    margin-top: 1rem; /* Margen superior para los encabezados */
    margin-left: 6vw;
  }

  .sub-section-nosotros p {
    font-size: 1.2rem; /* Ajusta el tamaño de fuente para párrafos */
    line-height: 1.5; /* Mejora el espaciado entre líneas */
  }

  .nosotros-section .line-nosotros {
    width: 80%; /* Ajusta el ancho de la línea */
    margin-bottom: 1rem; /* Espacio inferior para la línea */
  }

  .text-container-nosotros .line-nosotros-2 {
    width: 80%; /* Ajusta el ancho de la línea */
    margin-left: 6vw;
    margin-bottom: 1rem; /* Espacio inferior para la línea */
  }

  .nosotros-section-3 .line-nosotros {
    width: 100%; /* Ajusta el ancho de la línea */
    margin: 0 auto; /* Centra la línea */
    margin-bottom: 1rem; /* Espacio inferior para la línea */
  }
  .img-valores-nosotros {
    position: relative; /* Posicionar el contenedor de la imagen como relativo */
    overflow-x: auto; /* Permite el scroll horizontal */
    white-space: nowrap; /* Evita que las imágenes se envuelvan */
    background-color: #EC2227;
}

.img-valores-nosotros-2 {
  position: relative; /* Posicionar el contenedor de la imagen como relativo */
  overflow-x: auto; /* Permite el scroll horizontal */
  white-space: nowrap; /* Evita que las imágenes se envuelvan */
}

.itemservicios-nosotros {
  color: #54565A;
  padding: 1vw;
  text-align: left;
  flex: 1;
  font-family: 'Caveat';
  font-size: 10vw;
  margin-top: 8vw;
} 

/* Diferentes márgenes para cada item */
.itemservicios-nosotros:nth-child(1) {
  margin-left: 10vw;
  margin-right: 55vw; /* Primer item */
}

.itemservicios-nosotros:nth-child(2) {
  margin-right: 55vw; /* Segundo item */
}

.itemservicios-nosotros:nth-child(3) {
  margin-right: 45vw; /* Tercer item */
}

.itemservicios-nosotros:nth-child(4) {
  margin-right: 45vw; /* Tercer item */
}


.carousel-inner .carousel-item:nth-child(5) p{
  width: 85%;
  white-space: pre-wrap; /* Permite mostrar saltos de línea */

}
.scrollable-container-nosotros {
    display: inline-block; /* Permite que el contenedor se ajuste al tamaño del contenido */
    min-width: 100vw; /* Asegura que el contenedor sea al menos tan ancho como la ventana del navegador */
}

.nuestros-valores-img-nosotros {
    width: 102%; /* Permite que la imagen mantenga su tamaño original */
    height: auto; /* Mantiene la proporción de la imagen */
    margin-top: 0; /* Margen superior para la imagen */
    margin-bottom: 4rem; /* Margen inferior para la imagen */
    padding-left: 15vw;
    padding-right: 15vw;
}

.nuestros-valores-img-nosotros-2 {
  width: 102%; /* Permite que la imagen mantenga su tamaño original */
  height: auto; /* Mantiene la proporción de la imagen */
  margin-top: 0; /* Margen superior para la imagen */
  margin-bottom: 4rem; /* Margen inferior para la imagen */
  padding-left: 12vw;
  padding-right: 12vw;
}

.itemservicios-valores {
  color: #FEFEFE;
  padding: 1vw;
  text-align: left;
  flex: 1;
  font-family: 'Caveat';
  font-size: 10vw;
  margin-top: 8vw;
} 

/* Diferentes márgenes para cada item */
.itemservicios-valores:nth-child(1) {
  margin-right: 50vw; /* Primer item */
}

.itemservicios-valores:nth-child(2) {
  margin-right: 45vw; /* Segundo item */
}

.itemservicios-valores:nth-child(3) {
  margin-right: 45vw; /* Tercer item */
}
.space-between{
  margin-bottom: 10vw;
}
.touch-indicator-nosotros {
  position: absolute;
  top: 50%;
  left: 100%;
  width: 60px;
  height: 60px;
  background: rgba(236, 34, 39, 0.7);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: touchEffectNosotros 2s forwards;
  opacity: 0;
  animation-timing-function: ease-in-out;
  animation-play-state: paused; /* Inicia en pausa */
}

.second {
  animation: touchEffectSecondNosotros 2s forwards;
  animation-timing-function: ease-in-out;
  animation-play-state: paused;
  opacity: 0;
}

@keyframes touchEffectNosotros {
  0% {
    left: 100%;
    opacity: 0;
  }
  50% {
    left: 50%;
    opacity: 1;
  }
  100% {
    left: 0%;
    opacity: 0;
  }
}

@keyframes touchEffectSecondNosotros {
  0% {
    left: 100%;
    opacity: 0;
  }
  50% {
    left: 50%;
    opacity: 1;
  }
  100% {
    left: 0%;
    opacity: 0;
  }
}



}


/* Producto INDEX */

/* Sección de "producto" */

.productos-main-section {
  display: flex;
  justify-content: center; /* Centrar horizontalmente */
  align-items: center;     /* Centrar verticalmente */
  padding: 0%;
  margin-top: -10%;
}

.productos-main-section .primer-div {
  flex: 1;
  text-align: center;        /* Contenido alineado a la derecha */
  padding: 1%;
}

.productos-main-section .segundo-div {
  flex: 1;
  text-align: left;         /* Contenido alineado a la izquierda */
  padding: 2%;
  padding-left: 0;
  margin-left: -2vw;
}

.productos-main-section .line-nosotros{
  width: 61.5%;
  margin-left: 33%;
  margin-top: 1%;
}

.logo-producto-section{
  width: 30%;
  height: auto;
  margin-left: -4%;
}

.primer-div h2{
  font-size: 2rem;
  color: #e4002b;
  font-family: 'Raleway', sans-serif;
  text-align: left;
  margin-top: 1%;
  padding-left: 33%;
}

.primer-div p{
  font-size: 0.9vw;
  color: black;
  font-family: 'Raleway', sans-serif;
  text-align: left;
  padding-left: 33%;
  line-height: 1.1vw;
}

.banner-producto-section{
  width: 70%;
  height: auto;

}

.card-producto{
  display: flex;
  justify-content: center; /* Centrar horizontalmente */
  align-items: center;     /* Centrar verticalmente */
  padding-left: 0%;
  padding-right: 0%;
  padding-top: 2%;
  padding-bottom: 2%;
  margin-top: 1%;
}

.primer-div-card-producto {
  flex: 0.7; 
  text-align: center; 
  padding-left: 10%;
}

.segundo-div-card-producto {
  flex: 1.3; 
  padding: 10px;
  text-align: center; 
}

.producto-section {
  width: 65%;
  height: auto;
}

.card-producto-side-rigth{
  display: flex;
}


/* Estilos para la sección de presentaciones */
.div-slide-producto-pop {
  display: flex; /* Habilita flexbox */
  flex-direction: column; /* Asegúrate de que los elementos se apilen verticalmente */
  align-items: flex-start; /* Alinea todos los elementos al inicio (izquierda) */
  text-align: left; /* Alinea el texto a la izquierda */
}

.presentaciones-title {
  text-align: left;
  font-size: 1.2vw !important;
  margin-left: 0;
  color: #54565A;
}

.pop-slide-rigth {
  display: flex;
  flex-wrap: wrap;
  gap: 7%;
  justify-content: flex-start;
  padding-left: 4%;
}

.pop-slide-izquierda {
  flex: 0 0 11vw;
  padding: 0;
  text-align: left; /* Asegura que todo el contenido dentro de este div esté alineado a la izquierda */
}

.pop-slide-izquierda img {
  width: 20vw;
  height: auto;
}

.unidades-pop {
  font-size: 1.2vw !important;
  color: #54565A;
  font-weight: 500;
}

.div-slide-producto-pop-btn{
  flex: 0.5; 
  margin-top: 2.8vw;
  text-align: left;
  align-items: start;
}

.div-slide-producto-pop-btn-2{
  flex: 0.5; 
  margin-top: 0.4vw;
  text-align: left;
  align-items: start;
}

.btn-icon-producto{
  width: 8%;
  height: auto;
}
/* Media Queries para dispositivos móviles */
@media only screen and (max-width: 768px) {
  .productos-main-section {
    flex-direction: column; /* Cambia a una disposición en columna */
    margin-top: 0; /* Ajuste del margen superior */
  }

  .productos-main-section .primer-div,
  .productos-main-section .segundo-div {
    flex: 1; /* Ambos divs ocuparán el 100% del ancho en móviles */
    text-align: center; /* Centrar el contenido */
    padding: 5%; /* Añadir un poco de padding para espaciar el contenido */
  }

  .logo-producto-section {
    width: 60%; /* Ajusta el tamaño de la imagen para móviles */
    margin: 0 auto; /* Centra la imagen */
  }

  .primer-div h2 {
    font-size: 1.5rem; /* Ajusta el tamaño del texto en móviles */
    padding-left: 0; /* Elimina el padding lateral */
    text-align: center;
  }

  .primer-div p {
    font-size: 1rem; /* Ajusta el tamaño de la fuente para móviles */
    padding-left: 0; /* Elimina el padding lateral */
    line-height: 1.5rem; /* Ajusta el interlineado */
    text-align: center;
    text-align: justify;
    font-family: 'Raleway', sans-serif;
  }

  .banner-producto-section {
    width: 100%; /* La imagen del banner ocupará todo el ancho en móviles */
  }

  .card-producto {
    flex-direction: column; /* Coloca los elementos en una columna */
    padding: 5%; /* Ajusta el padding para móviles */
  }

  .primer-div-card-producto {
    flex: 1; /* Ambos divs ocuparán el 100% del ancho */
    text-align: center; /* Centra el contenido */
    padding: 0%;
  }

  .segundo-div-card-producto {
    flex: 1; /* Ambos divs ocuparán el 100% del ancho */
    text-align: center; /* Centra el contenido */
    width: 100%;
  }

  .producto-section {
    width: 100%; /* Ajusta el tamaño de la imagen del producto */
    
  }

  .card-producto-side-rigth {
    flex-direction: column; /* Los elementos estarán en una columna */
  }

  .div-slide-producto {
    text-align: left; /* Centrar el texto */
  }

  .div-slide-producto-2 {
    text-align: left; /* Centrar el botón */
    margin-top: 1rem; /* Añadir espacio entre el contenido y el botón */
  }

  .btn-producto {
    font-size: 0.8rem; /* Ajusta el tamaño del texto del botón */
    padding: 0.7rem 1.5rem; /* Ajusta el espaciado interno */
  }

  .linea-negra-productos {
    width: 80%; /* Ajusta el ancho de la línea para móviles */
    margin-left: 2vw;
  }

  .productos-main-section .line-nosotros{
    width: 60%;
    margin: 0 auto;
    margin-top: 2%;
  }

  .div-slide-producto p:nth-of-type(1) {
    font-size: 1.5rem !important; /* Cambia el tamaño para el primer párrafo */
  }

  .div-slide-producto p:nth-of-type(2) {
      font-size: 0.8rem !important; /* Cambia el tamaño para el segundo párrafo */
  }

  .div-slide-producto p:nth-of-type(3) {
      font-size: 1rem !important;   /* Cambia el tamaño para el tercer párrafo */
  }

  .div-slide-producto p:nth-of-type(4) {
      font-size: 0.8rem !important; /* Cambia el tamaño para el cuarto párrafo */
  }
  .div-slide-producto p:nth-of-type(5) {
    font-size: 1rem !important; /* Cambia el tamaño para el cuarto párrafo */
  }
  .pop-slide-izquierda {
    flex: 0 0 30vw;
    padding: 0;
    text-align: left; /* Asegura que todo el contenido dentro de este div esté alineado a la izquierda */
  }

  .unidades-pop {
    font-size: 2vw !important;
    color: #54565A;
    font-weight: 500;
  }

  .btn-icon-producto{
    width: 5%;
    height: auto;
  }
}


.alejandro{
  border-width: 1px;
  border-style: solid;
  border-color: blue;
}